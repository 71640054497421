import React, { useState, useEffect } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputTipoRelatorio, InputSocioAtivosComConselho, InputMercadoTipo, InputPeriodoGozo } from '../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup from '../../../util/Validacoes';
import { getUsuario } from '../../../util/Configuracoes'
import moment from "moment"

export default props => {
    const startOfMonth = moment().clone().startOf('month').format('DD/MM/YYYY')
    const endOfMonth = moment().clone().endOf('month').format('DD/MM/YYYY')
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Ocorrências')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '', remetente: null })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)
    const user = getUsuario()

    const tipoRelatorio = [
        { label: 'Mercado', value: 1 },
    ]

    useEffect(() => {
        changeTipo(1)
    }, [])

    const [validacao, setValidacao] = useState(Yup.object({}))

    const relatorioEspecifico = (array) => {
        const data = array[0].data
        const obj = {
            venda: data.filter(item => item.tipo_id === 1).map(item => item.dias_utilizar).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            devolucao: data.filter(item => item.tipo_id === 2).map(item => item.dias_utilizar).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            doacao: data.filter(item => item.tipo_id === 3).map(item => item.dias_utilizar).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            emprestimo: data.filter(item => item.tipo_id === 4).map(item => item.dias_utilizar).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
        }

        return data.length > 0 ? <>
            <table className="table-agrupamento">
                <tr>
                    <td className={`td-data text-center`} colspan={7}></td>
                    <td className={`td-data text-right bold`}>Totais: </td>
                </tr>
                <tr style={{ borderBottom: '1px #000 dashed' }}><td className={`td-data text-center`} colspan={1}></td></tr>
                {obj.venda > 0 ? <tr>
                    <td className={`td-data text-center`} colspan={7}></td>
                    <td className={`td-data text-right`}>Venda:</td>
                    <td className={`td-data text-right`}>{obj.venda}</td>
                </tr> : <></>}
                {obj.devolucao > 0 ? <tr>
                    <td className={`td-data text-center`} colspan={7}></td>
                    <td className={`td-data text-right`}>Devolução:</td>
                    <td className={`td-data text-right`}>{obj.devolucao}</td>
                </tr> : <></>}
                {obj.doacao > 0 ? <tr>
                    <td className={`td-data text-center`} colspan={7}></td>
                    <td className={`td-data text-right`}>Doação:</td>
                    <td className={`td-data text-right`}>{obj.doacao}</td>
                </tr> : <></>}
                {obj.emprestimo > 0 ? <tr>
                    <td className={`td-data text-center`} colspan={7}></td>
                    <td className={`td-data text-right`}>Empréstimo:</td>
                    <td className={`td-data text-right`}>{obj.emprestimo}</td>
                </tr> : <></>}
                <tr style={{ borderBottom: '1px #000 dashed' }}><td className={`td-data text-center`} colspan={1}></td></tr>
            </table>
        </> : <></>
    }

    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Relatório Mercado',
            tipo: 1,
            url: '/administracao/ferias/relatorioMercado',
            campos: [
                <InputPeriodoGozo label='Ano (Gozo)' name="periodo" propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} />,
                <InputMercadoTipo label="Tipo" name='mercado_tipo' propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSocioAtivosComConselho label="Sócio(s)" placeholder="Sócio(s)" name="socio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
            ],
            classeTr: 'linha-bottom-relatorio',
            colunas: [
                { label: 'Data', name: 'data', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo', name: 'tipo_descricao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Dias', name: 'dias_utilizar', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Remetente', name: 'remetente', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Destinatário', name: 'destinatario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: true, ordenamento: { tipo: 'texto', ativo: false } }
            ],
            data: [],
            colunasFiltros: [
                { label: 'Ano (Gozo)', name: 'periodo_gozo_id', name_coluna: 'periodo' },
                { label: 'Tipo', name: 'mercado_tipo_id', name_coluna: 'mercado_tipo' },
                { label: 'Sócio(s)', name: ['socio_remetente_id', 'socio_destinatario_id'], name_coluna: 'socio' },
            ],
            aposRelatorio: (dados) => relatorioEspecifico(dados)
        }
    ]

    const submit = (values, propsFormik) => {
        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)

        setShowModalFiltros(false)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio) {
            setFiltros(Object.keys(values).map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value) && valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }).filter(x => x && x.value))
        }
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }

        const newFiltro = [...filtros]

        if (user.tipo === 4)
            newFiltro.push({ coluna: 'usuario', value: user.id }, { coluna: 'tipo', value: user.tipo })

        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data, classeTr: cloneRelatorioAtual.classeTr, aposRelatorio: !!cloneRelatorioAtual.aposRelatorio ? (dados) => cloneRelatorioAtual.aposRelatorio(dados) : null }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: newFiltro })
    }, [filtros])

    const changeTipo = (value) => {
        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({})
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: tipoRelatorio.find(x => x.value === value), remetente: null })
        setRelatorioSelecionado(value)
    }

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })} orientacao={'portrait'}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        {/* <InputTipoRelatorio label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                            propsFormik={propsFormik} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} /> */}
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}