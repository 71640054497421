import React, { useState, useEffect } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputDatePicker, InputMonthPicker, InputNumero } from '../../../components/Inputs'
import { InputTipoRelatorio, InputSocio, InputPeriodoGozo, InputPeriodoFerias, InputEntidade, InputCategoriaAlternativo, InputStatusMovimentoFinanceiro, InputUsuario, InputClienteFinanceiro, InputMovimentoFinanceiro, InputSimNao, InputSocioAtivos, InputFiltroEstabelecimentoSaude, InputOperadoraSaude, InputTipoLancamento, InputContaBancaria, InputStatusCorLancamento, InputStatusLancamento, InputModoLiquidacao, InputAtendimentoFinanceiro } from '../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes'
import { checkAllUndefined, convertValueToFloat, formatarMoeda } from '../../../util/Utilitarios'
import * as moment from 'moment'
import { Link } from 'react-router-dom'
import { mostrarToast } from '../../../components/Toasts';
import { ordenarArrayObj } from '../../../util/FuncoesComuns';
import { RETENCOES, TIPO_LANCAMENTO } from '../../../util/Enums';
import { InputClienteFinanceiroV3, InputEmpresa, InputStatusEnvioNota, InputStatusNota } from '../../../components/InputsAutoCompleteV2';

export default props => {
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Lançamento Movimento Financeiro')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: { label: 'Lançamento Financeiro', value: 1 }, referencia: moment().format('MM/YYYY') })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)

    const validacao = Yup.object({})

    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Lançamento Movimento Financeiro',
            tipo: 1,
            aposRelatorio: calculoAgrupamento,
            url: '/financeiro/relatorioLancamentoMovimento',
            numerarLinha: true,
            campos: [
                <InputMonthPicker label="Referência" name="referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} max={new Date().getFullYear()} min={2022} />,
                <InputClienteFinanceiro label='Cliente' name='cliente' placeholder='Cliente' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputEmpresa label='Empresa' name='empresa' placeholder='Empresa' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputMovimentoFinanceiro label='Movimento' name='movimento' placeholder='Movimento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSimNao label='Liquidado?' name='liquidado' placeholder='Liquidado?' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label='Possui Movimento?' name='possui_movimento' placeholder='Possui Movimento?' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} />,
                <InputAtendimentoFinanceiro label='Atendimento' name='atendimento' placeholder='Atendimento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputEmpresa label="Prestador/Empresa" placeholder="Prestador/Empresa" name="nota_fiscal_empresa" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputClienteFinanceiroV3 label="Tomador/Cliente" placeholder="Tomador/Cliente" name="nota_fiscal_cliente" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSocioAtivos label='Sócio' name='socio' placeholder='Sócio' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputFiltroEstabelecimentoSaude label='Estabelecimento de Saúde' name='estabelecimento_saude' placeholder='Estabelecimento de Saúde' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputOperadoraSaude label='Operadora de Saúde' name='operadora_saude' placeholder='Operadora de Saúde' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputModoLiquidacao label='Tipo Pagamento' name='tipo_pagamento' placeholder='Tipo Pagamento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputTipoLancamento label='Tipo de Lançamento' name='tipo_lancamento' placeholder='Tipo de Lançamento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputCategoriaAlternativo label='Categoria' name='categoria' placeholder='Categoria' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputContaBancaria label='Conta Bancária' name='conta' placeholder='Conta Bancária' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputStatusCorLancamento label='Estado Lançamento' name='estado' placeholder='Estado Lançamento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputStatusLancamento label='Status' name='status' placeholder='Status' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputStatusNota label="Status NFSe" placeholder="Status NFSe" name="nota_fiscal_status_nota" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputStatusEnvioNota label="Status Envio" placeholder="Status Envio" name="nota_fiscal_status_envio" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSimNao label="Possui NFSe?" name="nota_fiscal_possui_nfse" type="select" placeholder="Possui NFSe?" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro NFSe Inicial" name="nota_fiscal_data_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("nota_fiscal_data_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro NFSe Final" name="nota_fiscal_data_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("nota_fiscal_data_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Emissão Inicial" name="nota_fiscal_data_emissao_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("nota_fiscal_data_emissao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Emissão Final" name="nota_fiscal_data_emissao_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("nota_fiscal_data_emissao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Lançamento Inicial" name="data_inicial_lancamento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_lancamento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Lançamento Final" name="data_final_lancamento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_lancamento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Liquidação Inicial" name="data_inicial_liquidacao" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_liquidacao", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Liquidação Final" name="data_final_liquidacao" type="text" setarValor={val => propsFormik.setFieldValue("data_final_liquidacao", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Inicial" name="data_inicial_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Final" name="data_final_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_final_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputUsuario label="Usuário Responsável" name="usuario" placeholder='Usuário Responsável' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} menuPlacement='top' multiplo />
            ],
            colunas: [
                { label: 'Cod.', name: 'id', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Movimento', name: 'movimento_financeiro_id', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'P', name: 'numero_parcela', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Aten.', name: 'atendimento', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Data', name: 'data_prevista', visivel: true, ordenamento: { tipo: 'data', ativo: true } },
                { label: 'Cliente', name: 'entidade', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Empresa', name: 'empresa', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sócio(s)', name: 'socio', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estab./Op. de Saúde', name: 'estab_op_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', width: '10%', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', totalizar: true },
                { label: 'Data Liquidação', name: 'data_liquidacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Retenções', width: '8%', name: 'total_retencao', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', totalizar: true },
                { label: 'Valor Liquidado', width: '10%', name: 'valor_liquidado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', totalizar: true },
                { label: 'TP', width: '8%', name: 'tipo_pagamento', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'NFSe', name: 'nfse_numero', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Usuário', name: 'usuario', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: false, ordenamento: { tipo: 'data', ativo: false } },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Referência', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Cliente', name: 'cliente', name_coluna: 'cliente' },
                { label: 'Empresa', name: 'empresa', name_coluna: 'empresa' },
                { label: 'Movimento', name: 'movimento', name_coluna: 'movimento' },
                { label: 'Atendimento', name: 'atendimento', name_coluna: 'atendimento' },
                { label: 'Liquidado?', name: 'liquidado', name_coluna: 'liquidado' },
                { label: 'Possui Movimento?', name: 'possui_movimento', name_coluna: 'possui_movimento' },
                { label: 'Sócio', name: 'socio', name_coluna: 'socio' },
                { label: 'Usuário', name: 'usuario', name_coluna: 'usuario' },
                { label: 'Estabelecimento de Saúde', name: 'estabelecimento_saude', name_coluna: 'estabelecimento_saude' },
                { label: 'Operadora de Saúde', name: 'operadora_saude', name_coluna: 'operadora_saude' },
                { label: 'Tipo Pagamento', name: 'tipo_pagamento', name_coluna: 'tipo_pagamento' },
                { label: 'Tipo de Lançamento', name: 'tipo_lancamento', name_coluna: 'tipo_lancamento' },
                { label: 'Categoria', name: 'categoria', name_coluna: 'categoria' },
                { label: 'Conta Bancária', name: 'conta', name_coluna: 'conta' },
                { label: 'Estado Lançamento', name: 'estado', name_coluna: 'estado' },
                { label: 'Status', name: 'status', name_coluna: 'status' },

                { label: 'Data Lançamento Inicial', name: 'data_inicial_lancamento', name_coluna: 'data_inicial_lancamento' },
                { label: 'Data Lançamento Final', name: 'data_final_lancamento', name_coluna: 'data_final_lancamento' },
                { label: 'Data Liquidação Inicial', name: 'data_inicial_liquidacao', name_coluna: 'data_inicial_liquidacao' },
                { label: 'Data Liquidação Final', name: 'data_final_liquidacao', name_coluna: 'data_final_liquidacao' },
                { label: 'Data Registro Inicial', name: 'data_inicial_registro', name_coluna: 'data_inicial_registro' },
                { label: 'Data Registro Final', name: 'data_final_registro', name_coluna: 'data_final_registro' },

                //FILTROS NOTA FISCAL

                { label: 'Prestador/Empresa', name: 'nota_fiscal_empresa_id', name_coluna: 'nota_fiscal_empresa' },
                { label: 'Tomador/Cliente', name: 'nota_fiscal_cliente_id', name_coluna: 'nota_fiscal_cliente' },
                { label: 'Data Registro NFSe Inicial', name: 'nota_fiscal_data_inicial', name_coluna: 'nota_fiscal_data_inicial' },
                { label: 'Data Registro NFSe Final', name: 'nota_fiscal_data_final', name_coluna: 'nota_fiscal_data_final' },
                { label: 'Data Emissão Inicial', name: 'nota_fiscal_data_emissao_inicial', name_coluna: 'nota_fiscal_data_emissao_inicial' },
                { label: 'Data Emissão Final', name: 'nota_fiscal_data_emissao_final', name_coluna: 'nota_fiscal_data_emissao_final' },
                { label: 'Status Envio', name: 'nota_fiscal_status_envio_id', name_coluna: 'nota_fiscal_status_envio' },
                { label: 'Status Nota', name: 'nota_fiscal_status_id', name_coluna: 'nota_fiscal_status_nota' },
                { label: 'Possui NFSe?', name: 'nota_fiscal_possui_nfse', name_coluna: 'nota_fiscal_possui_nfse' },
            ],
        }
    ]

    const calculoAgrupamento = (dadosAgr, itemColuna, arrayConfigCol) => {

        let array = dadosAgr && dadosAgr.length > 0 && dadosAgr[0].data.filter(x => x.valor_liquidado).flatMap(x => x.tipo_pagamento_liquidado)
        let arrayRet = dadosAgr && dadosAgr.length > 0 && dadosAgr[0].data.filter(x => x.valor_liquidado).flatMap(x => x.retencoes_acrescimos)

        let arrayLancReceitas = dadosAgr && dadosAgr.length > 0 && dadosAgr[0].data.filter(x => x.valor_liquidado).filter(x => x.tipo_lancamento_id == TIPO_LANCAMENTO.RECEITA).flatMap(x => x.tipo_pagamento_liquidado)

        const resultReceitas = arrayLancReceitas.reduce((agrupado, transacao) => {
            if (!agrupado[transacao.sigla_modo]) {
                agrupado[transacao.sigla_modo] = {
                    sigla_modo: transacao.sigla_modo,
                    tipo_pagamento: transacao.tipo_pagamento,
                    valor_liquidado: 0,
                    ordenamento: transacao.ordenamento
                };
            }
            agrupado[transacao.sigla_modo].valor_liquidado += transacao.valor_liquidado;
            return agrupado;
        }, {});
        const resultadoLancReceitas = Object.values(resultReceitas);

        let arrayLancDepesas = dadosAgr && dadosAgr.length > 0 && dadosAgr[0].data.filter(x => x.valor_liquidado).filter(x => x.tipo_lancamento_id == TIPO_LANCAMENTO.DESPESA).flatMap(x => x.tipo_pagamento_liquidado)

        const resultDespesas = arrayLancDepesas.reduce((agrupado, transacao) => {
            if (!agrupado[transacao.sigla_modo]) {
                agrupado[transacao.sigla_modo] = {
                    sigla_modo: transacao.sigla_modo,
                    tipo_pagamento: transacao.tipo_pagamento,
                    valor_liquidado: 0,
                    ordenamento: transacao.ordenamento
                };
            }
            agrupado[transacao.sigla_modo].valor_liquidado += transacao.valor_liquidado;
            return agrupado;
        }, {});

        const resultadoLancDespesas = Object.values(resultDespesas);

        let aggRetencoes = arrayRet.reduce((agrupado, transacao) => {
            agrupado["acrescimo"] = (agrupado["acrescimo"] || 0) + (transacao.acrescimo || 0)
            agrupado["desconto"] = (agrupado["desconto"] || 0) + (transacao.desconto || 0)
            agrupado["juros_multas"] = (agrupado["juros_multas"] || 0) + (transacao.juros_multas || 0)
            agrupado["imposto_nfse"] = (agrupado["imposto_nfse"] || 0) + (transacao.imposto_nfse || 0)
            agrupado["glosas"] = (agrupado["glosas"] || 0) + (transacao.glosas || 0)
            agrupado["outros_impostos"] = (agrupado["outros_impostos"] || 0) + (transacao.outros_impostos || 0)
            agrupado["total_retencao"] = (agrupado["total_retencao"] || 0) + (transacao.total_retencao || 0)
            return agrupado;
        }, {});
        const aggKeysRet = Object.keys(aggRetencoes);

        return (<>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ width: '43%' }}>
                    <table style={{ width: '100%' }}>
                        <tr className={'tr-data'}>
                            <td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Resumo dos Lançamentos Liquidados(Receitas)</span></td>
                        </tr>
                        <tr className='tr-data titulo-1'>
                            <td className='text-center bold td-data' width={'5%'}>#</td>
                            <td className='bold td-data' width={'65%'}>Tipo de Pagamento</td>
                            <td className='text-right bold td-data' width={'30%'}>Valor(R$)</td>
                        </tr>
                        {resultadoLancReceitas && resultadoLancReceitas.length > 0 ? ordenarArrayObj(resultadoLancReceitas, 'ordenamento').map((trans, idTrans) => {
                            return (
                                <tr className='tr-data'>
                                    <td className='text-center td-data'>{idTrans + 1}</td>
                                    <td className='td-data'>{trans.tipo_pagamento}</td>
                                    <td className='text-right td-data'>{formatarMoeda(trans.valor_liquidado)}</td>
                                </tr>
                            )
                        }) :
                            <tr className='tr-data'>
                                <td colSpan={3} className='text-center td-data'><span>Não há liquidações</span></td>
                            </tr>
                        }
                    </table>
                    <table style={{ width: '100%' }}>
                        <tr className={'tr-data'}>
                            <td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}> Resumo dos Lançamentos Liquidados (Despesas)</span></td>
                        </tr>
                        <tr className='tr-data titulo-1'>
                            <td className='text-center bold td-data' width={'5%'}>#</td>
                            <td className='bold td-data' width={'65%'}>Tipo de Pagamento</td>
                            <td className='text-right bold td-data' width={'30%'}>Valor(R$)</td>
                        </tr>
                        {resultadoLancDespesas && resultadoLancDespesas.length > 0 ? ordenarArrayObj(resultadoLancDespesas, 'ordenamento').map((trans, idTrans) => {
                            return (
                                <tr className='tr-data'>
                                    <td className='text-center td-data'>{idTrans + 1}</td>
                                    <td className='td-data'>{trans.tipo_pagamento}</td>
                                    <td className='text-right td-data'>{formatarMoeda(-trans.valor_liquidado)}</td>
                                </tr>
                            )
                        }) :
                            <tr className='tr-data'>
                                <td colSpan={3} className='text-center td-data'><span>Não há liquidações</span></td>
                            </tr>
                        }
                    </table>
                </div>
                <div style={{ width: '43%' }}>
                    <table style={{ width: '100%' }}>
                        <tr className={'tr-data'}>
                            <td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Resumo Retenções</span></td>
                        </tr>
                        <tr className='tr-data titulo-1'>
                            <td className='text-center bold td-data' width={'5%'}>#</td>
                            <td className='bold td-data' width={'65%'}>Tipo de Retenção</td>
                            <td className='text-right bold td-data' width={'30%'}>Valor(R$)</td>
                        </tr>
                        {aggKeysRet && aggKeysRet.filter(name => aggRetencoes[name] > 0).length > 0 ? aggKeysRet.filter(name => aggRetencoes[name] > 0).map((name, idName) => {
                            return (
                                <tr className='tr-data'>
                                    <td className='text-center td-data'>{idName + 1}</td>
                                    <td className='td-data'>{RETENCOES[name]}</td>
                                    <td className='text-right td-data'>{formatarMoeda(aggRetencoes[name])}</td>
                                </tr>
                            )
                        }) :
                            <tr className='tr-data'>
                                <td colSpan={3} className='text-center td-data'><span>Não há liquidações</span></td>
                            </tr>
                        }
                    </table>
                </div>
            </div>

        </>)
    }


    const submit = async (values, propsFormik) => {
        if (values && checkAllUndefined(values.movimento, values.referencia, values.data_inicial_lancamento, values.data_final_lancmento, values.data_inicial_liquidacao, values.data_final_liquidacao, values.data_inicial_registro, values.data_final_registro)) {
            mostrarToast('erro', 'Escolha ao menos um filtro! Movimento, Referência, Data Movimento ou Data Registro')
            return;
        }
        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === 1)
        values.tipo_relatorio = 1
        setShowModalFiltros(false)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]


        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio) {
            setFiltros(Object.keys(values).map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: x === 'duracao_minima' || x === 'duracao_maxima' ? valorAtual.split(':')[0].replace(/_/g, '').toString().padStart(2, '0') + 'h ' +
                        valorAtual.split(':')[1].split(/_/g, '').toString().padStart(2, '0') + 'min' : typeof valorAtual === 'string' ? valorAtual : valorAtual.length ?
                            valorAtual.map(y => y.label_personalizada || y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }
            ).filter(x => x))
        }
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }
        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ agrupado: cloneRelatorioAtual?.agrupado || false, aposRelatorio: cloneRelatorioAtual.aposRelatorio, numerarLinha: cloneRelatorioAtual.numerarLinha !== undefined ? cloneRelatorioAtual.numerarLinha : true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data, relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento) : null }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: filtros })
    }, [filtros])

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })}
            agrupamento={[{ coluna: 'nome', descricao: 'nome' }]}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}

